import React, { useEffect } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

const Testimonials = () => {

  useEffect(() => {
    // slider
    ;(function() {
      const testimonialElts = Array.from(
        document.getElementsByClassName("testimonial")
      )
      const bulletPtElts = Array.from(
        document.getElementsByClassName("bullet-pt")
      )
      const leftArrowElt = document.getElementById("testi-left-arrow")
      const rightArrowElt = document.getElementById("testi-right-arrow")
      const sliderSpeed = 0

      let currentSlideIndex = 0
      let slideMoving = false

      testimonialElts[currentSlideIndex].style.display = "block"
      bulletPtElts[currentSlideIndex].style.backgroundColor = "white"

      rightArrowElt.addEventListener("click", () => {
        if (!slideMoving) {
          let currentSlide = testimonialElts[currentSlideIndex]
          currentSlide.style.animation = `slideRightOut ${sliderSpeed}ms`
          slideMoving = true
          setTimeout(() => {
            currentSlide.style.display = "none"
            slideMoving = false
          }, sliderSpeed)
          bulletPtElts[currentSlideIndex].style.backgroundColor = "grey"
          currentSlideIndex++
          if (currentSlideIndex === testimonialElts.length) {
            currentSlideIndex = 0
          }
          testimonialElts[currentSlideIndex].style.display = "block"
          testimonialElts[
            currentSlideIndex
            ].style.animation = `slideRightIn ${sliderSpeed}ms`
          bulletPtElts[currentSlideIndex].style.backgroundColor = "white"
        }
      })

      leftArrowElt.addEventListener("click", () => {
        if (!slideMoving) {
          let currentSlide = testimonialElts[currentSlideIndex]
          currentSlide.style.animation = `slideLeftOut ${sliderSpeed}ms`
          slideMoving = true
          setTimeout(() => {
            currentSlide.style.display = "none"
            slideMoving = false
          }, sliderSpeed)
          bulletPtElts[currentSlideIndex].style.backgroundColor = "grey"
          currentSlideIndex--
          if (currentSlideIndex < 0) {
            currentSlideIndex = testimonialElts.length - 1
          }
          testimonialElts[currentSlideIndex].style.display = "block"
          testimonialElts[
            currentSlideIndex
            ].style.animation = `slideLeftIn ${sliderSpeed}ms`
          bulletPtElts[currentSlideIndex].style.backgroundColor = "white"
        }
      })
    })()
  }, [])

  return (
    <div css={TestimonialsSectionStyles}
         style={{
           width:"100%"
         }}
    >
      <section id="section-testimonials">
        <h2 className="gutter">mission eased, experts fulfilled</h2>
        <h5 className="gutter">Words From Our Sponsors</h5>
        <div id="testimonials-slider-ctn">
          <SliderSide>
            <div className="testi-arrow" id="testi-left-arrow">
              &#60;
            </div>
          </SliderSide>
          <div id="testimonials-content-ctn">
            <div className="testimonial">
              <blockquote>
                <p>
                  Nadia is a wonderful person. She is very talented and
                  dedicated to give her experience to the service of others.
                </p>
                <p>
                  As my line manager, I truly appreciated to work with her. She
                  has all the qualities of a manager you dream of: charismatic,
                  experienced, trustworthy, devoted, organized, positive and
                  benevolent.
                </p>
                <p>
                  She has excellent communication, problem-solving skills and
                  has a remarkable ability to solve any complex situations
                  thanks to her extremely wide expertise (data-management,
                  management, IT and audit). Her reliability, strong expertise,
                  diligence and hard-working abilities are an incontestable
                  asset for an oriented-services work. On top of her strong
                  expertise, Nadia is birght, intelligent and a very pleasant
                  and king person to work with.
                </p>
                <p>
                  I would really appreciate to work with her again soon and I
                  highly recommend her.
                </p>
                <footer>
                  <span>Anna PROK - TIEV</span>
                  <span>Director, Clinical Data Management chez Kantar</span>
                </footer>
              </blockquote>
            </div>
            <div className="testimonial">
              <blockquote>
                <p>
                  I had the pleasure of working with Nadia and the Focus Data
                  Science team on a project in which they developed a web-based
                  system to collect survey data from hospitals. Nadia was
                  approachable and professional, and was very thorough in
                  outlining the project plan and technical details.
                </p>
                <p>
                  The final product was of high quality, and my client commented
                  on how impresssed they were with the overall look-and-feel and
                  functionality of the interface.
                </p>
                <p>A job well done!</p>
                <footer>
                  <span>Robert Hutcheson, MSc, PMP</span>
                  <span>Senior Manager at Certara Evidence & Access</span>
                </footer>
              </blockquote>
            </div>
            <div className="testimonial">
              <blockquote>
                <p>
                  Travailler avec Nadia a toujours été un plaisir! Sa rigueur et
                  son professionnalisme mènerons à bien tous vos projets en DM
                  et en IT.
                </p>
                <footer>
                  <span>Gwladys Gourdeau</span>
                  <span>Data manager (prestation L'Oréal) chez IT&M STATS</span>
                </footer>
              </blockquote>
            </div>
            <div className="testimonial">
              <blockquote>
                <p>
                  Nadia est professionnelle, rigoureuse, impliquée et généreuse.
                  Elle sait motiver et transmettre pour avancer et se surpasser.
                  Travailler à ses côtés a été une excellente expérience!
                </p>
                <footer>
                  <span>Céline Kernaleguen</span>
                  <span>Senior Data Manager chez stève consultants</span>
                </footer>
              </blockquote>
            </div>
            <div id="bullet-pts-ctn">
              <div className="bullet-pt" />
              <div className="bullet-pt" />
              <div className="bullet-pt" />
              <div className="bullet-pt" />
            </div>
          </div>
          <SliderSide>
            <div className="testi-arrow" id="testi-right-arrow">
              &#62;
            </div>
          </SliderSide>
        </div>
      </section>
    </div>
  )
}

export default Testimonials

const TestimonialsSectionStyles = css`
  #section-testimonials {  
    padding-bottom: 5rem;
    margin-bottom: -1px;

    h2 {
      margin: 0
    }
  }

  #section-testimonials h5 {
    margin-bottom: 2rem;
  }

  #testimonials-slider-ctn {
    display: grid;
    grid-template-columns: 1fr minmax(0, 50rem) 1fr;
    height: 34rem;
    margin-bottom: 6rem;
    overflow: hidden;
  }

  #testimonials-content-ctn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fafafb;
    position: relative;
  }

  .testimonial {
    border: 0.0625rem solid #fafafb;
    border-radius: 1rem;
    position: absolute;
    display: none;
    max-height: 31rem;
    overflow: auto;
  }


  #bullet-pts-ctn {
    display: flex;
    justify-content: space-between;
    width: 4rem;
    align-self: flex-end;
  }

  .bullet-pt {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 100%;
    background-color: grey;
  }

  .testi-arrow {
    font-size: 2rem;
    padding: 0 0.5rem;
    cursor: pointer;
    position: absolute;
    top: 12.875rem;
    color: white;
  }

  #testi-left-arrow {
    right: 0.4rem;
  }

  #testi-right-arrow {
    left: 0.4rem;
  }

  #testimonials-content-ctn blockquote {
    margin: 2rem;
  }

  #testimonials-content-ctn footer span {
    display: block;
    margin-bottom: 0.5rem;
    color: #969696;
  }

  #references-ctn {
    max-width: 57.273rem;
    margin: auto;
  }

  .testimonials-gatsby-image {
    display: block;
    width: 100%;
    margin-bottom: 3rem;
  }

  @media (max-width: 1023px) {
    #section-testimonials {
      padding-bottom: 6rem;
      padding-top: 2rem;
    }
    #testi-right-arrow{    
      left: -30vw;
      top: 32.35rem;
    }
    #testi-left-arrow{
      left:30vw;
      top: 32.35rem;
    }
    .slider-side{
      z-index:2 !important;
    }
  }

  @media (max-width: 767px) {
    #section-testimonials {
     
    }
  }

  @media (max-width: 479px) {
    #section-testimonials {
      
    }
  }

  @media (max-width: 374px) {
    #section-testimonials {
      
    }
  }
`
const SliderSide = styled.div`
  position: relative;
  background-color: rgba(1, 9, 33, 1);
  z-index: 1;
  min-width: 2rem;

  @media screen and (max-width: 900px){
    background : transparent;
  }
`
